/*
 * @Author: Gang Jiang 
 * @Date: 2025-02-19 21:16:05 
 * @Last Modified by: Gang Jiang
 * @Last Modified time: 2025-03-03 14:46:01
 */
 <template>
  <div class="help-page">
    <breadcrumbs url="about-artemis-ads" />
    <div class="docs">
      <h1>Brand Referral Bonus Rates by Category</h1>
      <h2>
        What are the typical Brand Referral Bonus rates for different product
        categories?
      </h2>
      <p>
        The Brand Referral Bonus (BRB) percentage varies by category, with an
        average bonus of around 10% on sales generated from external traffic.
        Below are common category rates:
      </p>
      <artmisads-table
        bordered
        :data-source="data"
        :columns="column"
        :pagination="false"
      />
      <h2>Where can I find the latest bonus rates?</h2>
      <p>
        Amazon updates Brand Referral Bonus rates periodically. Sellers can
        check the most up-to-date rates in their Amazon Seller Central dashboard
        or refer to
        <a
          href="https://sellercentral.amazon.com/help/hub/reference/external/GL9HPJ34VBFP76HX?initialSessionID=apay%3D147-0673581-9434758&ld=NSGoogle"
          target="_blank"
          >Amazon's official guide</a
        >.
      </p>
      <p>
        These rates help sellers estimate their potential earnings when
        directing external traffic to Amazon.
      </p>
    </div>
  </div>
</template>
<script setup>
import Breadcrumbs from '../breadcrumbs.vue'

const data = [
  {
    categories: 'Clothing & Accessories (including active wear)',
    estimatedBonusRate: '11%',
  },
  {
    categories:
      'Books (including Collectible Books); Music; Software & Computer/Video Games; Video & DVD; Collectible Books; Home & Garden; Kitchen; Mattresses; Musical Instruments; Office Products; Outdoors; Sports (excluding Sports Collectibles); Pet Supplies; Toys & Games; Luggage & Travel Accessories; Shoes, Handbags & Sunglasses; Everything Else',
    estimatedBonusRate: '10%',
  },
  {
    categories:
      '3D Printed Products; Industrial & Scientific (including Food Service and Janitorial & Sanitation)',
    estimatedBonusRate: '8%',
  },
  {
    categories:
      'Camera and Photo; Cell Phone Devices; Consumer electronics; Full-Size Appliance; Personal Computers; Video Game Consoles',
    estimatedBonusRate: '5%',
  },
  {
    categories: 'Tools & Home Improvement',
    estimatedBonusRate: '10%, except 8% for base equipment power tools',
  },
  {
    categories: 'Automotive & Power sports',
    estimatedBonusRate: '8%, except 7% for tires and wheel products',
  },
  {
    categories:
      'Baby Products (excluding Baby Apparel); Beauty; Health & Personal Care (including Personal Care Appliances);',
    estimatedBonusRate:
      '5% for products with a total price of $10.00 or less, and, 10% for products with a total sales prices greater then $10.00',
  },
  {
    categories: 'Compact Appliances (including parts and accessories)',
    estimatedBonusRate:
      '10% for the portion of the total sales price up to $300.00, and, 5% for any portion of the total sales price greater than $300.00',
  },
  {
    categories: 'Electronics Accessories',
    estimatedBonusRate:
      '10% for the portion of the total sales price up to $100.00, and, 5% for any portion of the total sales price greater than $100.00',
  },
  {
    categories: 'Furniture (including outdoor furniture)',
    estimatedBonusRate:
      '10% for the portion of the total sales price up to $200.00, and, 7% for any portion of the total sales price greater than $200.00',
  },
  {
    categories: 'Jewelry',
    estimatedBonusRate:
      '14% for the portion of the total sales price up to $250.00, and, 3% for any portion of the total sales price greater than $250.00',
  },
  {
    categories: 'Watches',
    estimatedBonusRate:
      '11% for the portion of the total sales price up to $1,500.00, and, 2% for any portion of the total sales price greater than $1,500.00',
  },
]
const column = [
  {
    key: 'categories',
    dataIndex: 'categories',
    title: 'Categories',
  },
  {
    width: '40%',
    key: 'estimatedBonusRate',
    dataIndex: 'estimatedBonusRate',
    title: 'Estimated Bonus Rate*',
  },
]
</script>